import { useState } from 'react';

export const VIEWS = [
  'MENU',
  'EVENT_DATA',
  'PERSONAL_DATA',
];

function useMenuForm() {
  const [currentView, setView] = useState(VIEWS[0]);
  const [isSubmitted, setSubmitted] = useState(false);

  return {
    currentView,
    setView,
    isSubmitted,
    setSubmitted,
  };
}

export default useMenuForm;
