import { useState } from 'react';

function useSidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    toggle,
  };
}

export default useSidebar;
