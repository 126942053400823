import React from 'react';
// import { Provider } from 'react-redux';
// import createStore from './src/store';
import 'normalize.css/normalize.css';
import './src/styles/index.scss';
import StateProvider from './src/container/StateProvider/StateProvider';

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const { store } = createStore();
  // return (
  //   <Provider store={store}>{element}</Provider>
  // );
  return (
    <StateProvider>
      {element}
    </StateProvider>
  );
}
