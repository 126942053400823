import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useState from '../../state';

export const StateContext = createContext({});

function StateProvider({ children }) {
  const state = useState();

  return (
    <StateContext.Provider value={state}>
      {children}
    </StateContext.Provider>
  );
}

StateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StateProvider;
