// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-anfrage-versendet-jsx": () => import("./../../../src/pages/anfrage-versendet.jsx" /* webpackChunkName: "component---src-pages-anfrage-versendet-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menue-zusammenfassung-jsx": () => import("./../../../src/pages/menue-zusammenfassung.jsx" /* webpackChunkName: "component---src-pages-menue-zusammenfassung-jsx" */),
  "component---src-pages-online-menue-planer-jsx": () => import("./../../../src/pages/online-menue-planer.jsx" /* webpackChunkName: "component---src-pages-online-menue-planer-jsx" */),
  "component---src-pages-weihnachts-menue-jsx": () => import("./../../../src/pages/weihnachts-menue.jsx" /* webpackChunkName: "component---src-pages-weihnachts-menue-jsx" */)
}

