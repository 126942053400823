import useSidebar from './hooks/sidebar';
import useMenuForm from './hooks/menuForm';

function useState() {
  const sidebar = useSidebar();
  const menuForm = useMenuForm();
  const state = {
    sidebar,
    menuForm,
  };

  console.log('%c State change:', 'font-weight: bold;');
  console.log(state);

  return state;
}

export default useState;
